<template>
  <div class="card mt-4 flex">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <website-texts-item
            @website-texts-item-edit="websiteTextsItemEdit"
            v-for="ws in this.websiteTexts"
            v-bind:website-text="ws"
            v-bind:key="ws.id"
          >
          </website-texts-item>
        </div>
      </div>
    </div>

    <div class="m-2">
      <button type="button" @click="redirectToNewWebsiteTextsPage()" class="btn btn-primary">
        New
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    websiteTexts: { type: Object, required: true },
    baseRouteWebsiteTexts: { type: String, required: true },
    baseRouteWebsiteTextsCreate: { type: String, required: true },
    baseRouteWebsiteTextsChange: { type: String, required: true },
  },
  mounted() {},
  methods: {
    redirectToNewWebsiteTextsPage() {
      window.location = this.baseRouteWebsiteTextsCreate;
    },
    websiteTextsItemEdit(id) {
      window.location = this.baseRouteWebsiteTextsChange + "/" + id;
    },
  },
};
</script>

<style></style>
