<template>
  <div>
    <div class="container-fluid bg-light rounded mb-2">
      <div class="row">
        <div class="col-10 p-1">
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">ID:</p>
              <div class="ml-2">{{ this.websiteText.id }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Site:</p>
              <div class="ml-2">{{ this.websiteText.site }}</div>
            </div>
            <div class="col d-flex flex-row">
              <p class="">Is Headline:</p>
              <div class="ml-2">{{ this.websiteText.isHeadline }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Text:</p>
              <div class="ml-2">{{ this.websiteText.text }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Created at:</p>
              <div class="ml-2">{{ this.websiteText.created_at }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Updated at:</p>
              <div class="ml-2">{{ this.websiteText.updated_at }}</div>
            </div>
          </div>
        </div>
        <div class="col-2 p-4">
          <div class="row" style="height: 25px">
            <button
              type="button"
              class="btn btn-link"
              @click="editWebsiteText(this.websiteText)"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    websiteText: { type: Object, required: true },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    editWebsiteText(project) {
      this.$emit("website-texts-item-edit", this.websiteText.id);
    },
  },
};
</script>
