<template>
  <div class="card mt-4 flex">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <projects-item
            @projects-item-edit="projectsItemEdit"
            @projects-item-delete="projectsItemDelete"
            v-for="project in this.projects"
            v-bind:project="project"
            v-bind:skills="skills"
            v-bind:key="project.id"
          >
          </projects-item>
        </div>
      </div>
    </div>

    <div class="m-2">
      <button type="button" @click="redirectToNewProjectsPage()" class="btn btn-primary">
        New
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projects: { type: Object, required: true },
    skills: { type: Object, required: true },
    baseRouteProjects: { type: String, required: true },
    baseRouteProjectsCreate: { type: String, required: true },
    baseRouteProjectsChange: { type: String, required: true },
    baseRouteProjectsDestroy: { type: String, required: true },
  },
  mounted() {},
  methods: {
    redirectToNewProjectsPage() {
      window.location = this.baseRouteProjectsCreate;
    },
    projectsItemEdit(id) {
      window.location = this.baseRouteProjectsChange + "/" + id;
    },
    projectsItemDelete(id) {
      axios
        .delete(this.baseRouteProjectsDestroy+ "/" + id)
        .then((response) => {
          if (response.data.success == 1) {
            window.location = this.baseRouteProjects;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
