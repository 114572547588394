<template>
  <div class="bg-secondary">
    <div class="input-group p-2">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">ID:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="ID"
        aria-label="ID"
        aria-describedby="basic-addon1"
        required
        v-model="this.skill.id"
        readonly
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Sort Nr.:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="SORT NUMBER"
        aria-label="Sort Number"
        aria-describedby="basic-addon1"
        required
        v-model="this.skill.sortNr"
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Rating:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="RATING"
        aria-label="Rating"
        aria-describedby="basic-addon1"
        required
        v-model="this.skill.rating"
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Name:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="NAME"
        aria-label="Name"
        aria-describedby="basic-addon1"
        required
        v-model="this.skill.name"
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Type:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="TYPE"
        aria-label="type"
        aria-describedby="basic-addon1"
        required
        v-model="this.skill.type"
      />
    </div>
    <div class="input-group px-2 pt-2">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Created:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="CREATED AT"
        aria-label="Created at"
        aria-describedby="basic-addon1"
        required
        v-model="this.skill.created_at"
        readonly
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Updated:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="UPDATED AT"
        aria-label="Updated at"
        aria-describedby="basic-addon1"
        required
        v-model="this.skill.updated_at"
        readonly
      />
    </div>
    <div class="p-2 d-flex justify-content-around">
      <div class="p-2">
        <button type="button" @click="update()" class="btn btn-primary">Update</button>
      </div>
      <div class="p-2">
        <button type="button" @click="destroy()" class="btn btn-primary">Delete</button>
      </div>
      <div class="p-2">
        <button type="button" @click="close()" class="btn btn-primary">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    skill: { type: Object, required: true },
    baseRouteSkills: { type: String, required: true },
    baseRouteSkillsUpdate: { type: String, required: true },
    baseRouteSkillsDestroy: { type: String, required: true },
  },

  mounted() {
    //  console.log("Component skill-item mounted.");
  },
  methods: {
    edit(skill) {
      this.$emit("skills-item-edit", this.skill);
    },
    update() {
      axios
        .put(this.baseRouteSkillsUpdate, {
          id: this.skill.id,
          type: this.skill.type,
          sortnr: this.skill.sortNr,
          rating: this.skill.rating,
          name: this.skill.name,
        })
        .then((response) => {
          if (response.data.success == 1) {
            window.location = this.baseRouteSkills;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    destroy() {
      axios
        .delete(this.baseRouteSkillsDestroy + "/" + this.skill.id)
        .then((response) => {
          if (response.data.success == 1) {
            window.location = this.baseRouteSkills;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    close() {
      this.$emit("skills-item-close", this.skill);
    },
  },
};
</script>
