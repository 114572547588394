<template>
  <div class="card mt-4 flex">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Sort Nr.:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="SORT NUMBER"
        aria-label="Sort Nrumber"
        aria-describedby="basic-addon1"
        required
        name="sortNr"
        v-model="this.projectItem.sortNr"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Year start:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="YEAR START"
        aria-label="Year Start"
        aria-describedby="basic-addon1"
        required
        name="yearStart"
        v-model="this.projectItem.yearStart"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Year end:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="YEAR END"
        aria-label="Year End"
        aria-describedby="basic-addon1"
        required
        name="yearEnd"
        v-model="this.projectItem.yearEnd"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Company</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="COMPANY"
        aria-label="Company"
        aria-describedby="basic-addon1"
        required
        name="company"
        v-model="this.projectItem.company"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Company country:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="AT"
        aria-label="Company Country"
        aria-describedby="basic-addon1"
        required
        name="companyCountry"
        v-model="this.projectItem.companyCountry"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Company link:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="WWW.YOURCOMPNY.COM"
        aria-label="Company Link"
        aria-describedby="basic-addon1"
        required
        name="companyLink"
        v-model="this.projectItem.companyLink"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Additional Link:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="WWW.YOURLINK.COM"
        aria-label="Additional Link"
        aria-describedby="basic-addon1"
        name="additionalLink"
        v-model="this.projectItem.additionalLink"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Additional Link Typ:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="YOUTUBE OR GIT"
        aria-label="Additional Link Type"
        aria-describedby="basic-addon1"
        name="additionalLinkType"
        v-model="this.projectItem.additionalLinkTyp"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Skills:</span>
      </div>
      <Multiselect
        class="form-control"
        v-model="selectedSkills"
        mode="tags"
        placeholder="Select your characters"
        :options="mulitSelectSkills"
        :search="true"
      />
    </div>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Description:</span>
      </div>
      <textarea
        class="form-control"
        aria-label="Description"
        placeholder="DESCRIPTION"
        required
        name="description"
        cols="15"
        rows="8"
        v-model="this.projectItem.description"
      ></textarea>
    </div>
    <div class="m-2">
      <button type="button" @click="create()" class="btn btn-primary">Create it</button>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
export default {
  props: {
    baseRouteProjects: { type: String, required: true },
    baseRouteProjectsCreate: { type: String, required: true },
    skills: { type: Object, required: true },
  },
  components: { Multiselect },
  mounted() {
    this.projectItem.additionalLink = "";
    this.projectItem.additionalLinkType = "NOLINK";
    //Convert skills for multi select
    this.skills.forEach((skill) => {
      this.mulitSelectSkills.push(skill.name);
    });
  },
  data() {
    return {
      projectItem: {},
      selectedSkills: [],
      mulitSelectSkills: [],
    };
  },
  methods: {
    create() {
      //Convert skills name to id
      var sendSkills = new Array();
      this.selectedSkills.forEach((skill) => {
        this.skills.forEach((skillObj) => {
            if(skill == skillObj.name) {
                sendSkills.push(skillObj.id);
            }
        });
      });
      axios
        .post(this.baseRouteProjectsCreate, {
          sortNr: this.projectItem.sortNr,
          yearStart: this.projectItem.yearStart,
          yearEnd: this.projectItem.yearEnd,
          company: this.projectItem.company,
          companyCountry: this.projectItem.companyCountry,
          companyLink: this.projectItem.companyLink,
          description: this.projectItem.description,
          additionalLink: this.projectItem.additionalLink,
          additionalLinkType: this.projectItem.additionalLinkType,
          skills: sendSkills,
        })
        .then((response) => {
          if (response.data.success == 1) {
            window.location = this.baseRouteProjects;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    redirectProjectsPage() {
      window.location = this.baseRouteProjects;
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
