import './bootstrap';

//VUE
import { createApp } from 'vue';

const app = createApp({})


//Skills
import SkillsEdit from './components/skills/SkillsEdit.vue'
import SkillsEditItem from './components/skills/SkillsEditItem.vue'
import SkillsItem from './components/skills/SkillsItem.vue'
app.component('skills-edit', SkillsEdit)
app.component('skills-edit-item', SkillsEditItem)
app.component('skills-item', SkillsItem)

//Timelines
import TimelinesEdit from './components/timelines/TimelinesEdit.vue'
import TimelinesEditItem from './components/timelines/TimelinesEditItem.vue'
import TimelinesItem from './components/timelines/TimelinesItem.vue'
app.component('timelines-edit', TimelinesEdit)
app.component('timelines-edit-item', TimelinesEditItem)
app.component('timelines-item', TimelinesItem)

//Projects
import ProjectsEdit from './components/projects/ProjectsEdit.vue'
import ProjectsEditItem from './components/projects/ProjectsEditItem.vue'
import ProjectsItem from './components/projects/ProjectsItem.vue'
import ProjectsCreate from './components/projects/ProjectsCreate.vue'
import ProjectsUpdate from './components/projects/ProjectsUpdate.vue'
app.component('projects-edit', ProjectsEdit)
app.component('projects-edit-item', ProjectsEditItem)
app.component('projects-item', ProjectsItem)
app.component('projects-create', ProjectsCreate)
app.component('projects-update', ProjectsUpdate)

//Website texts
import WebsiteTextsEdit from './components/websiteTexts/WebsiteTextsEdit.vue'
import WebsiteTextsItem from './components/websiteTexts/WebsiteTextsItem.vue'
import WebsiteTextsCreate from './components/websiteTexts/WebsiteTextsCreate.vue'
import WebsiteTextsUpdate from './components/websiteTexts/WebsiteTextsUpdate.vue'
app.component('website-texts-edit', WebsiteTextsEdit)
app.component('website-texts-item', WebsiteTextsItem)
app.component('website-texts-create', WebsiteTextsCreate)
app.component('website-texts-update', WebsiteTextsUpdate)

app.mount('#app')


//Portfolio
const sections = document.querySelectorAll('.section');
const sectBtns = document.querySelectorAll('.controlls');
const sectBtn = document.querySelectorAll('.control');
const allSections = document.querySelector('.main-content');


function PageTransitions() {
    //Button click active class
    for (let i = 0; i < sectBtn.length; i++) {
        sectBtn[i].addEventListener('click', function () {
            let currentBtn = document.querySelectorAll('.active-btn');
            //  currentBtn[0].className = currentBtn[0].className.replace('active-btn','');
            //  this.className += ' active-btn';
        })
    }

    //Sections Active class
    if (allSections) {
        allSections.addEventListener('click', (e) => {
            const id = e.target.dataset.id;
            if (id) {
                //remove slected from the other btns
                sectBtns.forEach((btn) => {
                    //  btn.classList.remove('active');
                })
                // e.target.classList.add('active');

                //hide other sections
                sections.forEach((section) => {
                    //  section.classList.remove('active');
                })

                const element = document.getElementById(id);
                // element.classList.add('active');
            }
        })
    }

    //Toggle theme
    const themeBtn = document.querySelector('.theme-btn');
    if (themeBtn) {
        themeBtn.addEventListener('click', () => {
            let element = document.body;
            // element.classList.toggle('light-mode');
            const dm = localStorage.getItem("lightMode");
            if (dm == "false") {
                element.classList.add('light-mode');
                localStorage.setItem("lightMode", "true");
            }
            else {
                localStorage.setItem("lightMode", "false");
                element.classList.remove('light-mode');
            }
        })

        const dm = localStorage.getItem("lightMode");
        let element = document.body;
        if (dm == "false") {
            element.classList.remove('light-mode');
        }
        else {
            element.classList.add('light-mode');
        }
    }
}

PageTransitions();
