<template>
  <div class="card mt-4 flex">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Site:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="SITE"
        aria-label="Site"
        aria-describedby="basic-addon1"
        required
        name="site"
        v-model="this.websiteText.site"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Is headline:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="IS HEADLINE"
        aria-label="Is headline"
        aria-describedby="basic-addon1"
        required
        name="isHeadline"
        v-model="this.websiteText.isHeadline"
      />
    </div>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Text:</span>
      </div>
      <textarea
        class="form-control"
        aria-label="Text"
        placeholder="tEXT"
        required
        name="text"
        cols="15"
        rows="8"
        v-model="this.websiteText.text"
      ></textarea>
    </div>
    <div class="m-2">
      <button type="button" @click="update()" class="btn btn-primary">Update it</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    baseRouteWebsiteTexts: { type: String, required: true },
    baseRouteWebsiteTextsUpdate: { type: String, required: true },
    websiteText: { type: Object, required: true },
  },
  mounted() {},
  data() {
    return {}
  },
  methods: {
    update() {
      axios
        .put(this.baseRouteWebsiteTextsUpdate, {
          id: this.websiteText.id,
          site: this.websiteText.site,
          isHeadline: this.websiteText.isHeadline,
          text: this.websiteText.text,
        })
        .then((response) => {
          if (response.data.success == 1) {
            window.location = this.baseRouteWebsiteTexts;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    redirectWebsiteTextsPage() {
      window.location = this.baseRouteWebsiteTexts;
    },
  },
};
</script>

