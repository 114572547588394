<template>
  <div>
    <div class="container-fluid bg-light rounded mb-2">
      <div class="row">
        <div class="col-10 p-1">
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">ID:</p>
              <div class="ml-2">{{ this.project.id }}</div>
            </div>
            <div class="col d-flex flex-row">
              <p class="">Sort Number:</p>
              <div class="ml-2">{{ this.project.sortNr }}</div>
            </div>
            <div class="col d-flex flex-row">
              <p class="">Year start:</p>
              <div class="ml-2">{{ this.project.yearStart }}</div>
            </div>
            <div class="col d-flex flex-row">
              <p class="">Year end:</p>
              <div class="ml-2">{{ this.project.yearEnd }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Company:</p>
              <div class="ml-2">{{ this.project.company }}</div>
            </div>
            <div class="col d-flex flex-row">
              <p class="">Country:</p>
              <div class="ml-2">{{ this.project.companyCountry }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Company link:</p>
              <div class="ml-2">{{ this.project.companyLink }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Add link type:</p>
              <div class="ml-2">{{ this.project.additionaLinkType }}</div>
            </div>
            <div class="col d-flex flex-row">
              <p class="">Add link:</p>
              <div class="ml-2">{{ this.project.additionaLink }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Description:</p>
              <div class="ml-2">{{ this.project.description }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Created at:</p>
              <div class="ml-2">{{ this.project.created_at }}</div>
            </div>
          </div>
          <div class="row" style="height: 25px">
            <div class="col d-flex flex-row">
              <p class="">Updated at:</p>
              <div class="ml-2">{{ this.project.updated_at }}</div>
            </div>
          </div>
          <div class="row" style="height: 50px">
            <div class="col d-flex flex-row">
              <Multiselect
                class="form-control"
                v-model="selectedSkills"
                mode="tags"
                placeholder="Select your characters"
                :options="mulitSelectSkills"
                :search="true"
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <div class="col-2 p-4">
          <div class="row" style="height: 25px">
            <button type="button" class="btn btn-link" @click="editProject(this.project)">
              Edit
            </button>
          </div>
          <div class="row" style="height: 25px">
            <button
              type="button"
              class="btn btn-link"
              @click="deleteProject(this.project)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
export default {
  props: {
    project: { type: Object, required: true },
    skills: { type: Object, required: true },
  },
  components: { Multiselect },
  mounted() {
    //Convert skills for multi select
    this.skills.forEach((skill) => {
      this.mulitSelectSkills.push(skill.name);
    });
    //Convert project skills for multi select
    this.project.skills.forEach((skill) => {
      this.selectedSkills.push(skill.name);
    });
  },
  data() {
    return {
      selectedSkills: [],
      mulitSelectSkills: [],
    };
  },
  methods: {
    editProject(project) {
      this.$emit("projects-item-edit", this.project.id);
    },
    deleteProject(project) {
      this.$emit("projects-item-delete", this.project.id);
    },
  },
};
</script>
