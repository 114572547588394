<template>
  <div class="card mt-4 flex">
    <div class="container-fluid">
      <div class="row">
        <div class="" :class="{ 'col-8': isEditActive, 'col-12': skillsFullWidth }">
          <skills-item
            @skills-item-edit="skillsItemEdit"
            v-for="skill in this.skills"
            v-bind:skill="skill"
            v-bind:key="skill.id"
          >
          </skills-item>
        </div>
        <div :class="{ 'col-4': isEditActive, goout: skillsFullWidth }">
          <skills-edit-item
            @skills-item-close="skillsItemEditClose"
            :skill="this.skillEditItem"
            :base-route-skills="this.baseRouteSkills"
            :base-route-skills-update="this.baseRouteSkillsUpdate"
            :base-route-skills-destroy="this.baseRouteSkillsDestroy"
          >
          </skills-edit-item>
        </div>
      </div>
    </div>

    <div class="m-2">
      <button type="button" @click="redirectToNewSkillsPage()" class="btn btn-primary">
        New
      </button>
    </div>
  </div>
</template>

<script>
import SkillsEditItem from "../skills/SkillsEditItem.vue";
export default {
  components: { SkillsEditItem },
  props: {
    skills: { type: Object, required: true },
    baseRouteSkills: { type: String, required: true },
    baseRouteSkillsCreate: { type: String, required: true },
    baseRouteSkillsUpdate: { type: String, required: true },
    baseRouteSkillsDestroy: { type: String, required: true },
  },
  mounted() {
  },
  data() {
    return {
      isEditActive: false,
      skillsFullWidth: true,
      skillEditItem: {},
    };
  },
  methods: {
    skillsItemEdit(skill) {
      this.skillEditItem = skill;
      this.openDataEdit();
    },
    skillsItemEditClose() {
      this.isEditActive = false;
      this.skillsFullWidth = true;
    },
    openDataEdit() {
      this.isEditActive = true;
      this.skillsFullWidth = false;
    },
    redirectToNewSkillsPage() {
      window.location = this.baseRouteSkillsCreate;
    },
  },
};
</script>

<style></style>
