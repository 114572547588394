<template>
  <div class="card mt-4 flex">
    <div class="container-fluid">
      <div class="row">
        <div class="" :class="{ 'col-8': isEditActive, 'col-12': timelinesFullWidth }">
          <timelines-item
            @timelines-item-edit="timelinesItemEdit"
            v-for="timeline in this.timelines"
            v-bind:timeline="timeline"
            v-bind:key="timeline.id"
          >
          </timelines-item>
        </div>
        <div :class="{ 'col-4': isEditActive, goout: timelinesFullWidth }">
          <timelines-edit-item
            @timelines-item-close="timelinesItemEditClose"
            :timeline="this.timelineEditItem"
            :base-route-timelines="this.baseRouteTimelines"
            :base-route-timelines-update="this.baseRouteTimelinesUpdate"
            :base-route-timelines-destroy="this.baseRouteTimelinesDestroy"
          >
          </timelines-edit-item>
        </div>
      </div>
    </div>

    <div class="m-2">
      <button type="button" @click="redirectToNewTimelinesPage()" class="btn btn-primary">
        New
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timelines: { type: Object, required: true },
    baseRouteTimelines: { type: String, required: true },
    baseRouteTimelinesCreate: { type: String, required: true },
    baseRouteTimelinesUpdate: { type: String, required: true },
    baseRouteTimelinesDestroy: { type: String, required: true },
  },
  mounted() {
  },
  data() {
    return {
      isEditActive: false,
      timelinesFullWidth: true,
      timelineEditItem: {},
    };
  },
  methods: {
    timelinesItemEdit(timeline) {
      this.timelineEditItem = timeline;
      this.openDataEdit();
    },
    timelinesItemEditClose() {
      this.isEditActive = false;
      this.timelinesFullWidth = true;
    },
    openDataEdit() {
      this.isEditActive = true;
      this.timelinesFullWidth = false;
    },
    redirectToNewTimelinesPage() {
      window.location = this.baseRouteTimelinesCreate;
    },
  },
};
</script>

<style></style>
