<template>
<div >
    <div class="container-fluid bg-light rounded mb-2">
        <div class="row">
            <div class="col-10 p-1">
                <div class="row" style="height: 25px">
                    <div class="col d-flex flex-row">
                        <p class="">ID:</p>
                        <div class="ml-2">{{this.skill.id}} </div>
                    </div>
                    <div class="col d-flex flex-row">
                        <p class="">Sort Number:</p>
                        <div class="ml-2">{{this.skill.sortNr}} </div>
                    </div>
                    <div class="col d-flex flex-row">
                        <p class="">Rating:</p>
                        <div class="ml-2">{{this.skill.rating}} </div>
                    </div>
                </div>
                <div class="row" style="height: 25px">
                    <div class="col d-flex flex-row">
                        <p class="">Name:</p>
                        <div class="ml-2">{{this.skill.name}} </div>
                    </div>
                    <div class="col d-flex flex-row">
                        <p class="">Skill type:</p>
                        <div class="ml-2">{{this.skill.type}} </div>
                    </div>
                </div>
                <div class="row" style="height: 25px">
                    <div class="col d-flex flex-row">
                        <p class="">Created at:</p>
                        <div class="ml-2">{{this.skill.created_at}} </div>
                    </div>
                    <div class="col d-flex flex-row">
                        <p class="">Updated at:</p>
                        <div class="ml-2">{{this.skill.updated_at}} </div>
                    </div>
                </div>
            </div>
            <div class="col-2 p-4">
                <div class="row" style="height: 25px">
                    <button type="button"
                            class="btn btn-link"
                            @click="edit(this.skill)">
                            Edit
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        props: {
            skill: { type: Object, required: true },
        },

        mounted() {
           // console.log('Component skill-item mounted.')
        },
        methods: {
            edit(skill) {
                this.$emit('skills-item-edit',this.skill);
            }
        }
    }
</script>
