<template>
  <div>
    <div class="container-fluid bg-light rounded mb-2">
      <div class="row">
        <div class="col-10 p-1">
          <div class="row">
            <div class="col d-flex flex-row">
              <p class="">ID:</p>
              <div class="ml-2">{{ this.timeline.id }}</div>
            </div>
            <div class="col d-flex flex-row">
              <p class="">Sort Number:</p>
              <div class="ml-2">{{ this.timeline.sortNr }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex flex-row">
              <p class="">Year start:</p>
              <div class="ml-2">
                {{ this.timeline.yearStart }} - {{ yearEndComputed }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex flex-row">
              <p class="text-break">{{ this.timeline.occupation }}</p>
            </div>
            <div class="col d-flex flex-row">
              <p class="text-break">{{ this.timeline.company }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex flex-row">
              <p class="text-break">{{ this.timeline.description }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex flex-row">
              <p class="">Created at:</p>
              <div class="ml-2">{{ this.timeline.created_at }}</div>
            </div>
            <div class="col d-flex flex-row">
              <p class="">Updated at:</p>
              <div class="ml-2">{{ this.timeline.updated_at }}</div>
            </div>
          </div>
        </div>
        <div class="col-2 p-4">
          <div class="row" style="height: 25px">
            <button type="button" class="btn btn-link" @click="edit(this.timeline)">
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timeline: { type: Object, required: true },
  },

  mounted() {
    // console.log('Component timeline-item mounted.')
  },
  methods: {
    edit(timeline) {
      this.$emit("timelines-item-edit", this.timeline);
    },
  },
  computed: {
    // a computed getter
    yearEndComputed() {
      return this.timeline.yearEnd == 0 ? "Present" : this.timeline.yearEnd;
    },
  },
};
</script>
