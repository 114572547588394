<template>
  <div class="bg-secondary">
    <div class="input-group p-2">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">ID:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="ID"
        aria-label="ID"
        aria-describedby="basic-addon1"
        required
        v-model="this.timeline.id"
        readonly
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Sort Nr.:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="SORT NUMBER"
        aria-label="Sort Number"
        aria-describedby="basic-addon1"
        required
        v-model="this.timeline.sortNr"
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Year start:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="YEAR START"
        aria-label="Year Start"
        aria-describedby="basic-addon1"
        required
        v-model="this.timeline.yearStart"
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Year end:</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="YEAR END"
        aria-label="Year End"
        aria-describedby="basic-addon1"
        required
        v-model="this.timeline.yearEnd"
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Occupation:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="OCCUPATION"
        aria-label="Occupation"
        aria-describedby="basic-addon1"
        required
        v-model="this.timeline.occupation"
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Company:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="COMPANY"
        aria-label="Company"
        aria-describedby="basic-addon1"
        required
        v-model="this.timeline.company"
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text">Description:</span>
      </div>
      <textarea
        class="form-control"
        aria-label="Description"
        placeholder="DESCRIPTION"
        required
        name="description"
        cols="15"
        rows="8"
        v-model="this.timeline.description"
      ></textarea>
    </div>
    <div class="input-group px-2 pt-2">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Created:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="CREATED AT"
        aria-label="Created at"
        aria-describedby="basic-addon1"
        required
        v-model="this.timeline.created_at"
        readonly
      />
    </div>
    <div class="input-group px-2 pt-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Updated:</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="UPDATED AT"
        aria-label="Updated at"
        aria-describedby="basic-addon1"
        required
        v-model="this.timeline.updated_at"
        readonly
      />
    </div>
    <div class="p-2 d-flex justify-content-around">
      <div class="p-2">
        <button type="button" @click="update()" class="btn btn-primary">Update</button>
      </div>
      <div class="p-2">
        <button type="button" @click="destroy()" class="btn btn-primary">Delete</button>
      </div>
      <div class="p-2">
        <button type="button" @click="close()" class="btn btn-primary">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timeline: { type: Object, required: true },
    baseRouteTimelines: { type: String, required: true },
    baseRouteTimelinesUpdate: { type: String, required: true },
    baseRouteTimelinesDestroy: { type: String, required: true },
  },

  mounted() {
    //  console.log("Component timeline-item mounted.");
  },
  methods: {
    edit(timeline) {
      this.$emit("timelines-item-edit", this.timeline);
    },
    update() {
      axios
        .put(this.baseRouteTimelinesUpdate, {
          id: this.timeline.id,
          sortNr: this.timeline.sortNr,
          yearStart: this.timeline.yearStart,
          yearEnd: this.timeline.yearEnd,
          occupation: this.timeline.occupation,
          company: this.timeline.company,
          description: this.timeline.description,
        })
        .then((response) => {
          if (response.data.success == 1) {
            window.location = this.baseRouteTimelines;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    destroy() {
      axios
        .delete(this.baseRouteTimelinesDestroy + "/" + this.timeline.id)
        .then((response) => {
          if (response.data.success == 1) {
            window.location = this.baseRouteTimelines;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    close() {
      this.$emit("timelines-item-close", this.timeline);
    },
  },
};
</script>
